var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-list",
    {
      scopedSlots: _vm._u([
        {
          key: "dropdownItens",
          fn: function() {
            return [
              _vm.$hasPerm("cadMotRemoverGuiaCriar")
                ? _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push({
                            name: "cadastros.motivoremoverguia.criar"
                          })
                        }
                      }
                    },
                    [_vm._v(" Novo motivo de remoção da guia ")]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "row mt-4" }, [
        _c("div", { staticClass: "col-md-2 col-sm-4 col-3" }, [
          _c(
            "label",
            { staticClass: "d-inline-flex align-items-center" },
            [
              _c("span", { staticClass: "d-none d-sm-inline" }, [
                _vm._v("Mostrar")
              ]),
              _c("b-form-select", {
                staticClass: "ms-2",
                attrs: { size: "sm", options: _vm.$tableItens },
                on: { input: _vm.getSearch },
                model: {
                  value: _vm.paginacao.itensPorPagina,
                  callback: function($$v) {
                    _vm.$set(_vm.paginacao, "itensPorPagina", $$v)
                  },
                  expression: "paginacao.itensPorPagina"
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          {
            staticClass: "col-md-10 col-sm-8 col-9 d-flex justify-content-end"
          },
          [
            _c(
              "label",
              { staticClass: "d-inline-flex align-items-center" },
              [
                _vm._v(" Filtrar: "),
                _c("b-form-input", {
                  directives: [
                    {
                      name: "debounce",
                      rawName: "v-debounce:450",
                      value: _vm.getSearch,
                      expression: "getSearch",
                      arg: "450"
                    }
                  ],
                  staticClass: "form-control form-control-sm ms-2",
                  attrs: { type: "search" },
                  model: {
                    value: _vm.filter.filtro,
                    callback: function($$v) {
                      _vm.$set(_vm.filter, "filtro", $$v)
                    },
                    expression: "filter.filtro"
                  }
                })
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _c("ui-table", {
            attrs: {
              colunas: _vm.colunas,
              items: _vm.items,
              "ordenacao-campo": _vm.ordenacao.campo,
              "ordenacao-direcao": _vm.ordenacao.direcao,
              loading: _vm.loading
            },
            on: {
              "update:ordenacaoCampo": function($event) {
                return _vm.$set(_vm.ordenacao, "campo", $event)
              },
              "update:ordenacao-campo": function($event) {
                return _vm.$set(_vm.ordenacao, "campo", $event)
              },
              "update:ordenacaoDirecao": function($event) {
                return _vm.$set(_vm.ordenacao, "direcao", $event)
              },
              "update:ordenacao-direcao": function($event) {
                return _vm.$set(_vm.ordenacao, "direcao", $event)
              },
              "force-refresh": _vm.getResults
            },
            scopedSlots: _vm._u([
              {
                key: "actions",
                fn: function(row) {
                  return [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "authorize",
                            rawName: "v-authorize",
                            value: "cadMotRemoverGuiaList",
                            expression: "'cadMotRemoverGuiaList'"
                          }
                        ],
                        attrs: {
                          variant: "outline-primary",
                          small: "",
                          title: "ver"
                        },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              name: "cadastros.motivoremoverguia.visualizar",
                              params: { id: row.data.id }
                            })
                          }
                        }
                      },
                      [_c("i", { staticClass: "uil uil-eye icon-size" })]
                    ),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "authorize",
                            rawName: "v-authorize",
                            value: "cadMotRemoverGuiaEditar",
                            expression: "'cadMotRemoverGuiaEditar'"
                          }
                        ],
                        attrs: {
                          variant: "outline-primary",
                          title: "editar",
                          small: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              name: "cadastros.motivoremoverguia.editar",
                              params: { id: row.data.id }
                            })
                          }
                        }
                      },
                      [_c("i", { staticClass: "uil uil-pen icon-size" })]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _c(
            "div",
            {
              staticClass: "dataTables_paginate paging_simple_numbers float-end"
            },
            [
              _c(
                "ul",
                { staticClass: "pagination pagination-rounded" },
                [
                  _c("b-pagination", {
                    attrs: {
                      "total-rows": _vm.paginacao.totalRegistros,
                      "per-page": _vm.paginacao.itensPorPagina
                    },
                    on: { input: _vm.getResults },
                    model: {
                      value: _vm.paginacao.pagina,
                      callback: function($$v) {
                        _vm.$set(_vm.paginacao, "pagina", $$v)
                      },
                      expression: "paginacao.pagina"
                    }
                  })
                ],
                1
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { api, param2query } from "./api.service";

export const getAll = (params, cancelToken) => {
  return api.get("motivoremoverguia/listar?" + param2query(params), cancelToken);
};

export const getAllMotivoRemoverGuia = (id) => {
  return api.get("motivoremoverguia/listar-motivoremoverguia/" + id);
};

export const getMotivoRemoverGuia = (id) => {
  return api.get("motivoremoverguia/" + id);
};

export const criarMotivoRemoverGuia = (model) => {
  return api.post("motivoremoverguia", model);
};

export const atualizarMotivoRemoverGuia = (model) => {
  return api.put("motivoremoverguia", model);
};

export const pesquisarMotivoRemoverGuia = (query) => {
  return api.get("motivoremoverguia/buscar-descricao?filtro=" + query);
};

export default { getAll };
